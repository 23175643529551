<template>
  <v-dialog
    v-model="Ekle"
    transition="dialog-top-transition"
    :max-width="1400"
    persistent
  >
    <template v-slot:default>
      <v-container>
        <v-card>
          <slot name="header">
            <v-toolbar
              color="primary"
              dark
            >
              {{ $t('GorevPlani.MANUEL_ICIN_PLAN') }}
              <v-spacer />
              <v-btn
                icon
                color="grey"
                @click="Ekle=false"
              >
                <v-icon>{{ icons.mdiWindowClose }}</v-icon>
              </v-btn>
            </v-toolbar>
          </slot>
        </v-card>
        <v-row>
          <v-col
            cols="12"
            class="py-1 my-1"
          >
            <v-data-table
              :headers="sutunlar"
              :items="GorevPlaniData"
            >
              <template v-slot:item.islem="{ item }">
                <v-btn
                  text
                  icon
                  color="primary"
                  @click="edit(item)"
                >
                  <v-icon>
                    {{ icons.mdiPencil }}
                  </v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  color="primary"
                  @click="remove(item)"
                >
                  <v-icon>
                    {{ icons.mdiTrashCanOutline }}
                  </v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  color="primary"
                  @click="editSchedule(item)"
                >
                  <v-icon>
                    {{ icons.mdiAccountDetails }}
                  </v-icon>
                </v-btn>
                <v-btn
                  text
                  icon
                  color="primary"
                  @click="editAutomaticSchedule(item)"
                >
                  <v-icon>
                    {{ icons.mdiAccountGroup }}
                  </v-icon>
                </v-btn>
              </template>
              <template v-slot:item.personel_sayisi="{ item }">
                <v-badge
                  color="primary"
                  :content="item.personel_sayisi"
                  offset-x="10"
                  offset-y="18"
                >
                  <v-avatar size="30">
                    <img
                      src="/img/person.png"
                      :alt="item.personel_sayisi"
                    >
                  </v-avatar>
                </v-badge>
              </template>
            </v-data-table>
            <silme-onayi
              :sil="sil"
              :title="evraktipi"
              @Sil="GorevPlaniSil"
            />
            <GorevPlaniGuncelle
              v-if="duzenle"
              :editdata="EditItem"
              @close="duzenle=false"
            />
            <GorevPlaniPersonelOtomatikGuncelle
              v-if="duzenle2"
              :editdata="EditItem"
              :show-modal="duzenle2"
              @showModal="duzenle2=$event"
              @setReload="setReload=$event"
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
  </v-dialog>
</template>

<script>
import {
  mdiAccountDetails, mdiAccountGroup, mdiPencil, mdiTrashCanOutline, mdiWindowClose,
} from '@mdi/js'
import api from '@/boot/axios'
import SilmeOnayi from '@/components/silme-onayi.vue'
import GorevPlaniGuncelle from '@/views/gorev-plani/gorev-plani-guncelle.vue'
import GorevPlaniPersonelOtomatikGuncelle from '@/views/gorev-plani/gorev-plan-personel-otomatik-guncelle.vue'

export default {
  name: 'GorevMutabakatiOlustur',
  components: { GorevPlaniPersonelOtomatikGuncelle, GorevPlaniGuncelle, SilmeOnayi },
  props: ['reload'],
  data() {
    return {
      GorevPlaniData: [],

      setReload: false,
      icons: {
        mdiPencil,
        mdiTrashCanOutline,
        mdiAccountDetails,
        mdiAccountGroup,
        mdiWindowClose,
      },
      data: {
        personel: null,
        musteri_id: null,
        adres_id: null,
        baslama_tarihi: this.Today(),
        bitis_tarihi: this.After(),
        baslama_saati: '09:00',
        bitis_saati: '19:00',
      },

      duzenle: false,
      duzenle2: false,
      EditItem: {},

      evraktipi: null,
      sil: false,
      silItem: null,

      sutunlar: [
        {
          align: 'start',
          text: this.$t('GorevPlani.islem'),
          value: 'islem',
        },
        {
          text: this.$t('GorevPlani.personel_sayisi'),
          align: 'start',
          value: 'personel_sayisi',
        },
        {
          text: this.$t('GorevPlani.firma'),
          value: 'firma_adi',
        },
        {
          text: this.$t('GorevPlani.musteri_adi'),
          value: 'musteri_adi',
        },

        {
          text: this.$t('GorevPlani.musteri_kod'),
          value: 'musteri_kod',
        },
        {
          text: this.$t('GorevPlani.cadde_nr'),
          value: 'cadde_nr',
        },
        {
          text: this.$t('GorevPlani.postakodu_sehir'),
          value: 'postakodu_sehir',
        },
        {
          text: this.$t('GorevPlani.ulke'),
          value: 'ulke',
        },
        {
          text: this.$t('GorevPlani.baslama_tarihi'),
          value: 'baslama_tarihi',
        },
        {
          text: this.$t('GorevPlani.baslama_saati'),
          value: 'baslama_saati',
        },
        {
          text: this.$t('GorevPlani.bitis_tarihi'),
          value: 'bitis_tarihi',
        },
        {
          text: this.$t('GorevPlani.bitis_saati'),
          value: 'bitis_saati',
        },
        {
          text: this.$t('GorevPlani.onay'),
          value: 'onay',
        },
      ],
    }
  },
  computed: {
    Ekle: {
      get() {
        return this.$store.state.createDialog
      },
      set(bool) {
        this.$store.state.createDialog = bool
      },
    },
    Musteriler() {
      return this.$store.state.Musteriler.Musteriler
    },
    MusteriAdresleri() {
      return this.$store.state.Musteriler.MusteriAdresleri
    },
    PersonelSelect() {
      return this.$store.state.Personeller.SelectPersonel
    },
    GorevPlani() {
      return this.$store.state.GorevPlani.GorevPlani
    },
    GorevPlaniPersonelDetaylari() {
      return this.$store.state.GorevPlani.GorevPlaniPersonelDetay
    },
    varSayilanFirma() {
      return this.$store.state.firma
    },
  },
  watch: {
    setReload: {
      handler() {
        if (this.setReload) {
          this.getFirmaGorevPlanlar()
          this.setReload = false
        }
      },
    },
    Ekle: {
      handler() {
        if (this.Ekle) {
          console.log(this.Ekle)
          this.getFirmaGorevPlanlar()
        }
      },
    },
  },
  methods: {
    remove(item) {
      this.silItem = item
      this.evraktipi = item.isim
      this.sil = true
    },
    async GorevPlaniSil(bool) {
      const { islem_kodu } = this.silItem
      this.sil = false
      if (bool) { // Evet tıklandı
        // eslint-disable-next-line camelcase
        await api.delete(`gorevplani/${islem_kodu}`)
        await this.getFirmaGorevPlanlar()
      }
    },
    edit(item) {
      this.EditItem = item
      this.duzenle = true
    },
    editAutomaticSchedule(item) {
      const data = { id: item.musteri_id }
      const item2 = JSON.parse(JSON.stringify(item))
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriTumu', data }).then(() => {
        // eslint-disable-next-line no-param-reassign,eqeqeq
        if (this.MusteriAdresleri.length == 1) {
          // eslint-disable-next-line no-param-reassign
          item2.adres_id = this.MusteriAdresleri[0].id
        }
        item2.musteri_adresleri = this.MusteriAdresleri
        this.EditItem = item2
        this.duzenle2 = true
        console.log(item2)
      })
    },
    async editSchedule(item) {
      const d = { ...item }
      await this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniDetayliGetir', data: d })

      // eslint-disable-next-line no-unused-vars

      const mapped = this.GorevPlaniPersonelDetaylari.items.map(a => ({
        ...a.map(f => ({
          ...f,
          bitis_tarihi: d.bitis_tarihi,
        })),
      }))
      mapped.sort((a, b) => a.calisma_tarihi - b.calisma_tarihi)
      const postData = {
        from: this.GorevPlaniPersonelDetaylari.from,
        to: this.GorevPlaniPersonelDetaylari.to,
        items: mapped,
        headers: this.GorevPlaniPersonelDetaylari.headers,
      }

      this.EditItem = postData
      console.log('EdıtItem:', this.EditItem)
      this.$router.push({ name: 'gorev-plani-manuel-guncelle', params: { id: d.islem_kodu } })
    },
    async manuelPersonelEditDataYukle(item) {
      const d = { ...item }
      await this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniDetayliGetir', data: d })
      const mapped = this.GorevPlaniPersonelDetaylari.items.map(a => ({
        ...a.map(f => ({
          ...f,
          bitis_tarihi: d.bitis_tarihi,
        })),
      }))
      mapped.sort((a, b) => a.calisma_tarihi - b.calisma_tarihi)
      const postData = {
        from: this.GorevPlaniPersonelDetaylari.from,
        to: this.GorevPlaniPersonelDetaylari.to,
        items: mapped,
        headers: this.GorevPlaniPersonelDetaylari.headers,
      }

      this.ekle = false
      this.$store.commit('GorevPlani/setManuelGuncelle', 1)
      this.$emit('setEditData', postData)
      this.$emit('setDuzenlePersonel', false)

      return console.log('Data Loaded Manuel Personel Edit Yukle.')
    },
    async getGorevPlaniUnselected() {
      await api.get('gorevplani', { params: { manuel_guncelle: 0, spesifik: 0 } }).then(response => {
        // eslint-disable-next-line consistent-return
        if (response?.data?.code === 200) {
          this.GorevPlaniData = response.data.result
        }
      })
    },
    async getFirmaGorevPlanlar() {
      const { data } = await api.get(`gorevplani-firma/${this.varSayilanFirma.id}`).catch(error => {
        if (error.response.status === 500) {
          this.GorevPlaniData = []
        }
      })
      if (data?.code === 200) {
        this.GorevPlaniData = data.result
      }
      console.log('GorevPlaniData', this.GorevPlaniData)
    },
    cModal() {
      this.$store.commit('Personeller/SelectPersonel', [])
      this.Ekle = false

      return console.log('Modal Kapatıldı...')
    },
    Today() {
      return this.DateFormat(new Date())
    },
    After() {
      const afterDay = 7 // Personel Ekleme yaparken varsayılan olarak 7 gün sonrası gelir.
      const date = new Date()
      date.setDate(date.getDate() + afterDay)

      return this.DateFormat(date)
    },
    DateFormat(date) {
      const d = new Date(date)
      let month = `${d.getMonth() + 1}`
      let day = `${d.getDate()}`
      const year = d.getFullYear()

      if (month.length < 2) month = `0${month}`
      if (day.length < 2) day = `0${day}`

      return [year, month, day].join('-')
    },
    MusteriSecildi(item) {
      const data = { id: item }
      this.$store.dispatch('Action', { name: 'Musteriler/MusteriAdresleriTumu', data }).then(() => {
        const adresSayisi = this.MusteriAdresleri?.length || 0
        // eslint-disable-next-line prefer-destructuring
        if (adresSayisi === 1) this.data.adres_id = this.MusteriAdresleri[0].id
      })
    },
    Olustur() {
      this.$emit('setDuzenlePersonel', true)
      this.Ekle = false
      this.data.firma_id = null
      this.GorevPlaniData = []

      /* / this.data.personel = this.PersonelSelect
      this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniOlustur', data: this.data }).then(() => {
        this.$store.dispatch('Action', { name: 'GorevPlani/GorevPlaniListesi' })
        this.Ekle = false
        this.$store.commit('Personeller/SelectPersonel', [])
        this.data = {
          personel: null,
          musteri_id: null,
          adres_id: null,
          baslama_tarihi: null,
          bitis_tarihi: null,
          baslama_saati: null,
          bitis_saati: null,
        }
      })/ */
    },
  },

}
</script>

<style scoped>

</style>
